/* CSS for landing page outside  */
:root {
  --primary-color: #09723f;
  --primary-yellow: #ffb566;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #191919;
}
a:hover {
  color: var(--primary-color);
}

.btn1:hover {
  color: white !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #191919;
  /* font-size: 13px; */
}
body {
  background: #fff;
  color: #191919;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 900 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active-link {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.875rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #191919;
}
.redColor {
  color: var(--primary-color);
}
.orangeColor {
  color: var(--primary-yellow);
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.configColor {
  color: var(--config-color);
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {
  background-color: var(--config-color);
}

.blueBg {
  background-color: var(--config-color);
}

.darkBgGradient {
  background: linear-gradient(
    -60deg,
    var(--config-color) 0%,
    var(--config-color) 40%,
    #00000015 40%,
    #00000015 100%
  );
}
.redBg {
  background-color: var(--config-color);
}
.redGradBg {
  background: linear-gradient(161deg, #f17255, var(--primary-color) 60%);
}
.lightBg {
  background-color: #f5f5f5;
}
.mainBg {
  background-color: var(--primary-color);
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #191919;
}
.whiteBg {
  background-color: #fff;
}

.line {
  top: 6px;
  width: 100%;
  height: 4px;
  float: left;
}

.circle {
  border: 4px solid white;
  background-color: var(--primary-color);
  height: 15px;
  border-radius: 50%;
  width: 15px;
}

.category {
  padding: 0px 20px;
  color: #191919;
  background-color: white;
  border-radius: 30px;
  height: 45px;
  width: fit-content;
  min-width: 80%;
  line-height: 1.2;
}
@media only screen and (max-width: 859px) {
  .category {
    width: 90%;
  }
}

.slick-arrow {
  background-color: transparent;
  width: 43px;
  height: 43px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

.slider-arrow {
  border-radius: "50%";
  min-width: 0;
  padding: 1;
}

.access-tile:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.scaleUp:hover {
  transform: scale(1.05);
  z-index: 9;
}

.overflow {
  overflow: auto;
}

.wrap {
  flex-wrap: wrap;
}

/* GAPS */
.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

/* PADDINGS */

.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}

/* DROPDOWN */

/* <div class="dropdown">
  <span>Mouse over me</span>
  <div class="dropdown-content">
    <p>Hello World!</p>
  </div>
  </div> */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  opacity: 0;
  visibility: hidden;
  transform: translate(100%);
  /* pointer-events: auto; */
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1;
}

.dropdown-content-active {
  opacity: 1;
  visibility: visible;
  transform: translate(0);
  /* pointer-events: none; */
}

.sidebar {
  row-gap: 20px;
  /* column-gap: 10px; */
  align-content: flex-start;
  padding: 10px;
  bottom: 0;
  height: 93%;
}

/* CUSTOM SCROLLBAR */

/* width */
/* .overflow::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
/* .overflow::-webkit-scrollbar-track {
  background: #ffffff00;
} */

/* Handle */
/* .overflow::-webkit-scrollbar-thumb {
  background: #00000010;
  border-radius: 8px;
} */

/* Handle on hover */
/* .overflow::-webkit-scrollbar-thumb:hover {
  background: #00000020;
} */

/* ANIMATION */

.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.borderBase {
  border-top: solid 1px #ccc;
}
