/* CSS for Ant Design customization */
:root {
  --selected-color: black;
}

.leftmenu .ant-menu-sub {
  background-color: rgba(9, 114, 63, 0.05) !important;
}

.leftmenu .ant-menu-item-selected {
  background-color: var(--selected-color) !important;
  color: white;
}

.leftmenu .ant-menu-item {
  padding-left: 20px !important;
  white-space: pre-wrap;
  line-height: 1.5 !important;
}

.leftmenu .ant-menu-submenu-title {
  height: fit-content !important;
  min-height: 40px;
  padding-left: 20px !important;
  white-space: pre-wrap;
  line-height: 1.5 !important;
}

.leftmenu .ant-menu-item .ant-menu-item-icon {
  font-size: large;
}

.leftmenu .ant-menu-submenu-title .ant-menu-item-icon {
  font-size: large;
}

.delete-icon:hover {
  color: #d70040;
}

.edit-icon:hover {
  color: #0096ff;
}

.ant-checkbox-group {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media only screen and (max-width: 990px) {
  .ant-drawer-content-wrapper {
    max-width: 300px !important;
  }
  .ant-menu {
    font-size: 12px !important;
  }
}

.ant-table-content > table > tbody > tr:nth-of-type(odd) > td {
  background-color: #f9f9f9;
}

.stepper .ant-steps-item-title {
  color: black !important;
}
