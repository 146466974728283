:root {
  --config-color: #09723f;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

p,
label {
  margin-bottom: 0.5rem;
}

.map-container {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact-show {
  padding: 2px 28px 2px 8px;
  visibility: hidden;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  visibility: hidden;
}

.register-public-user > div > label {
  font-size: inherit;
  font-weight: 400;
}

.register-public-user > div > div > .ant-select > .ant-select-selector {
  height: 52px !important;
  font-size: 14px;
  align-items: center;
}

.dashboard-filter > div > label {
  font-size: 12px;
  font-weight: 600;
}
.dashboard-filter > div {
  margin-bottom: 0 !important;
}

.dashboard-filter > div .ant-select-selector {
  font-size: 14px !important;
  height: 33px !important;
  border-radius: 6px !important;
}

.dashboard-filter > div .ant-select-selection-search-input {
  height: 30px !important;
}
.dashboard-filter > div .ant-select-selection-item {
  line-height: 35px !important;
}

/* CUSTOM SCROLLBAR */

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lst .col-sm-3 .form-group {
  margin-bottom: 0;
}

.col-25 {
  min-width: 200px;
}

.gYhPik .MuiPaper-root {
  min-width: 60% !important;
  min-height: 70% !important;
  max-height: 90% !important;
  max-width: 90% !important;
  border-radius: 15px;
}
